import { Decimal } from 'decimal.js'
import { StackingFrequency } from './calculation/types';
import isSameDay from 'date-fns/isSameDay'
import isSameMonth from 'date-fns/isSameMonth'
import isSameWeek from 'date-fns/isSameWeek'
import isSameYear from 'date-fns/isSameYear'
import lastDayOfMonth from 'date-fns/lastDayOfMonth'
import lastDayOfYear from 'date-fns/lastDayOfYear'

export class ResamplingUpError extends Error {}

const similarityFunction = (toFrequency: StackingFrequency) => {
    switch (toFrequency) {
        case "DAILY":
            return isSameDay;
        case "WEEKLY":
            return isSameWeek;
        case "MONTHLY":
            return isSameMonth;
        case "YEARLY":
            return isSameYear;
        default:
            throw new Error(`Unsuported toFrequency ${toFrequency}`)
    }
}

const FREQUENCY_ORDER = ["DAILY", "WEEKLY", "MONTHLY", "YEARLY"];

const selectWithAccumulation = <T>(data: T[], accumulatorFields: string[], acceptDate: Function): T[] => {
    const { filteredArray } = data
        .reduce(({ filteredArray, accumulators }, value, idx, dataArray) => {
            // accumulate values in the accumulators
            for (const fieldKey of accumulatorFields) {
                if (!accumulators[fieldKey]) accumulators[fieldKey] = new Decimal(0);
                accumulators[fieldKey] = accumulators[fieldKey].plus(value[fieldKey]);
            }

            // if this row is one we should retain,
            // write out the row with the accumulated values overwritten.
            if (acceptDate(value, idx, dataArray)) {
                const newFilteredArray = [...filteredArray, {
                    ...value,
                    ...accumulators,
                }]
                accumulators = {}; // re-set the accumulators
                return { filteredArray: newFilteredArray, accumulators };
            }

            return { filteredArray, accumulators };
        }, { filteredArray: [], accumulators: {} });

    return filteredArray;
}

export const resample = (data: any[], fromFrequency: StackingFrequency, toFrequency: StackingFrequency, startDate: Date) => {
    if (fromFrequency === toFrequency) return data;  // no resampling required
    if (FREQUENCY_ORDER.indexOf(toFrequency) < FREQUENCY_ORDER.indexOf(fromFrequency)) {
        throw new ResamplingUpError(`Trying to resample "up" from ${fromFrequency} to ${toFrequency}`);
    }

    const isSame = similarityFunction(toFrequency);

    const acceptDate = ({ date }, idx, array) => {
        if (idx === array.length - 1) return true;  // last row? always return
        const endDate = array[array.length - 1].date;
        const nextDate = idx < array.length - 1 ? array[idx + 1].date : null;

        if (fromFrequency === "DAILY") {
            if (toFrequency === "MONTHLY") {
                // preserve the last day of the last month (the goal date) - but no other days in that month
                if (isSameMonth(date, endDate)) {
                    if (date.getDate() === endDate.getDate()) return true;
                    else return false;
                }

                // use the last day of the month for all the other months, especially the first
                if (isSameDay(lastDayOfMonth(date), date)) return true;

                return false;
            }
            if (toFrequency === "WEEKLY") {
                if (isSameWeek(date, endDate) && date.getDate() !== endDate.getDate()) return false;
                if (date.getDay() === startDate.getDay()) return true;
                else return false;
            }
        }

        if (toFrequency === "YEARLY") {
            const lastDay = lastDayOfYear(date);

            if ((fromFrequency === "DAILY" || fromFrequency === "MONTHLY")) {
                if (
                    (fromFrequency !== "DAILY" || isSameDay(lastDay, date))
                    && isSameMonth(lastDay, date)
                ) return true;
                else return false;
            }

            if (fromFrequency === "WEEKLY") {
                if (isSameWeek(lastDay, date) || (nextDate && !isSameYear(nextDate, lastDay))) return true;
                else return false;
            }
        }

        // by the time we get here, we're only dealing with WEEKLY -> MONTHLY
        if (!isSame(nextDate, date)) return true;
        return false;
    };

    return selectWithAccumulation(data, ['fiatGain', 'stackedCrypto'], acceptDate);
}
