import { resample, ResamplingUpError } from 'utils/resampling';

import React from 'react';
import dynamic from 'next/dynamic';
import format from 'date-fns/format';
import { toCurrencyString } from 'utils/currency';
import { useCalculation } from 'components/content/calculator/calculationContext';
import { Decimal } from 'decimal.js';
import { SATOSHI_FACTOR } from '../../../../utils/convertBtcSats';

const Table = dynamic(() => import('components/atoms/table'));

const DateCell =
  ({ displayFrequency, stackingFrequency }) =>
  ({ value }) => {
    if (value instanceof Date) {
      let formatStr = 'dd MMM yy';
      if (displayFrequency === 'MONTHLY' && stackingFrequency !== 'MONTHLY')
        formatStr = 'MMM yy';
      if (displayFrequency === 'YEARLY') formatStr = 'yyyy';
      return format(value, formatStr) || '';
    } else {
      return value || '';
    }
  };
const DecimalPrecisionCell =
  (precision) =>
  ({ value }) =>
    value
      ? value
          .toNumber()
          .toLocaleString(undefined, {
            maximumFractionDigits: Math.max(precision - 1, 0),
          })
          .replace('-0', '0')
      : '';
const CurrencyCell =
  (currency) =>
  ({ value }) =>
    toCurrencyString(value.toNumber(), currency);

const getDisplayFrequencyDefault = ({ stackingFrequency, numTimePeriods }) => {
  if (stackingFrequency === 'DAILY') {
    if (numTimePeriods < 28) return 'DAILY';
    if (numTimePeriods >= 28 && numTimePeriods < 390) return 'WEEKLY';
    if (numTimePeriods >= 390) return 'YEARLY';
  } else if (stackingFrequency) {
    return stackingFrequency;
  }
};

const getDateColumnName = (displayFrequency) => {
  switch (displayFrequency) {
    case 'DAILY':
      return 'Date';
    case 'WEEKLY':
      return 'Week';
    case 'MONTHLY':
      return 'Month';
    case 'YEARLY':
      return 'Year';
    default:
      return 'Date';
  }
};

const ResultsTable = ({ tableProps }) => {
  const {
    isSatoshis,
    tableData,
    currency,
    stackingFrequency,
    startDate,
    numTimePeriods = 0,
    currentStack,
  } = useCalculation();

  const [displayFrequency, setDisplayFrequency] = React.useState('WEEKLY');
  const data = React.useMemo(() => {
    const baseData = tableData || [];
    try {
      return resample(baseData, stackingFrequency, displayFrequency, startDate);
    } catch (err) {
      if (err instanceof ResamplingUpError) {
        return baseData;
      }
      throw err;
    }
  }, [tableData, displayFrequency]); // eslint-disable-line react-hooks/exhaustive-deps
  const columns = React.useMemo(() => {
    const cols = [
      {
        Header: getDateColumnName(displayFrequency),
        accessor: 'date',
        Cell: DateCell({ displayFrequency, stackingFrequency }),
        sticky: true,
        color: 'text',
      },
      {
        Header: isSatoshis
          ? tableProps.stackedSatsLabel
          : tableProps.stackedBTCLabel,
        accessor: 'stackedCrypto',
        Cell: DecimalPrecisionCell(isSatoshis ? 0 : 8),
        color: 'secondaryGraphColor',
      },
      {
        Header: tableProps.gainLabel,
        accessor: 'fiatGain',
        Cell: CurrencyCell(currency),
        color: 'secondaryGraphColor',
      },
      {
        Header: tableProps.cumulativeInvestedFiatLabel,
        accessor: 'cumulativeInvestedFiat',
        Cell: CurrencyCell(currency),
        color: 'primary',
      },
      {
        Header: isSatoshis
          ? tableProps.cumulativeTotalSatsLabel
          : tableProps.cumulativeTotalBTCLabel,
        accessor: 'cumulativeTotalCrypto',
        Cell: DecimalPrecisionCell(isSatoshis ? 0 : 8),
        color: 'primary',
      },
      {
        Header: tableProps.totalGainLabel,
        accessor: 'cumulativeFiatGain',
        Cell: CurrencyCell(currency),
        color: 'primary',
      },
      {
        Header: tableProps.cumulativeTotalFiatLabel,
        accessor: 'cumulativeTotalFiat',
        Cell: CurrencyCell(currency),
        color: 'primary',
      },
    ];

    if (data[0]?.cumulativeTotalFiatPurchasingPower) {
      cols.push({
        Header: tableProps.purchasingPowerLabel,
        accessor: 'cumulativeTotalFiatPurchasingPower',
        Cell: CurrencyCell(currency),
        color: 'primary',
      });
    }

    return cols;
  }, [tableProps, data, isSatoshis, currency, displayFrequency]);

  React.useEffect(() => {
    const newDisplayFrequency = getDisplayFrequencyDefault({
      stackingFrequency,
      numTimePeriods,
    });
    if (newDisplayFrequency) setDisplayFrequency(newDisplayFrequency);
  }, [stackingFrequency, numTimePeriods]);

  const fixedData = [];
  data.forEach((item, idx) => {
    const newCumulativeFiatGain = new Decimal(item.cumulativeFiatGain.toFixed(2))
    const _item = (idx === 0) ? {...item, fiatGain: new Decimal(0), cumulativeFiatGain: newCumulativeFiatGain } : { ...item };
    if (isSatoshis) {
      _item.stackedCrypto = _item.stackedCrypto?.mul(SATOSHI_FACTOR);
      _item.cumulativeTotalCrypto = _item.cumulativeTotalCrypto?.mul(SATOSHI_FACTOR);
    };
    fixedData.push(_item);
  });

  return (
    <>
      <Table
        title={tableProps.title}
        data={fixedData}
        columns={columns}
        stackingFrequency={stackingFrequency}
        displayFrequency={displayFrequency}
        setDisplayFrequency={setDisplayFrequency}
      />
    </>
  );
};

export default ResultsTable;
